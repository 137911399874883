import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import TextButton from "../../Core/Buttons/TextButton"
import { InputContainerStyle } from "../Styles"
import axios from "axios"
import { getShopifyCustomerData } from "../../../services/shopify/queries"
import id from "../../../services/tracking/id"
import { isBrowser } from "../../../services/core/browser"
import { cvgTrack } from "../../../services/tracking/converge"

const FormContainer = styled.div`
  ${fonts.garamondRegular};
  max-width: 423px;
  margin: auto;
  width: 100%;
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  color: ${colors.charcoal};
  font-size: 32px;
  margin-top: .67em;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.15;

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    letter-spacing: normal;
  }
`

const InputContainer = styled.div`
  ${InputContainerStyle}
`

const SubmitButton = styled.button`
  ${ButtonStyleDark};
  max-width: unset;
`

const Navigations = styled.div`
  margin-top: 40px;
  display: grid;
  grid-gap: 30px;
  justify-content: center;
  text-align: center;

  a {
    letter-spacing: 1px;
    span {
      padding-bottom: 6px;
    }
  }
`

const RegisterButtonContainer = styled.div`
  display: grid;
`

const Claimer = styled.div`
  ${fonts.sofiaPro};
  margin-top: 4px;
  font-size: 12px;
`

const ServerError = styled.p`
  font-size: 14px;
  letter-spacing: .25px;
  color: #c00;
`

const LoginForm = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const getTotalSpent = (orders) => {
    let total = 0;
    orders.forEach(({node}) => {
      const price = parseFloat(node.totalPrice)

      total += price
    })

    return total
  }

  const dlLoginTrack = async (customer) => {
    if (isBrowser()) {
      const dataLayer = window.dataLayer;
      const eventId = id.userDataEventId()
      const orderCount = customer.orders.edges.length;
      const totalSpent = orderCount === 0 ? 0 : getTotalSpent(customer.orders.edges)
      cvgTrack({
        eventName: 'Logged In',
        profileProperties: {
          visitor_type: 'logged_in',
          '$email': customer.email,
          '$customer_id': customer.id.split('/').pop(),
          'customer_order_count': orderCount,
          'customer_total_spent': totalSpent
        },
        aliases: [`urn:email:${customer.email}`]
      });
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          "event": "dl_login",
          "event_id": eventId,
          "user_properties": {
            "visitor_type": "logged_in",
            "customer_id": customer.id,
            "customer_email": customer.email,
            "customer_order_count": customer.orders.edges.length,
            "customer_total_spent": totalSpent
          }
        });
      }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const customerAccountUrl = '/.netlify/functions/shopifyAccount'

    const customerAccountData = {
      "email": email,
      "password": password
    }

    let customerAccountResponse = {}

    try {
      customerAccountResponse = await axios.post(customerAccountUrl, customerAccountData)
    } catch (error) {
      console.log(error)
      customerAccountResponse.status = 500
    }

    const loginUrl = '/.netlify/functions/shopifyLogin'

    const loginData = {
      "email": email,
      "returnTo": `${process.env.GATSBY_SITE_HOSTNAME}/account`
    }

    let multipassResponse = {}

    if (customerAccountResponse.status === 200) {
      multipassResponse = await axios.post(loginUrl, loginData)
    } else {
      multipassResponse.status = 500
    }

    if (customerAccountResponse.status === 200 && multipassResponse.status === 200) {
      window.localStorage.setItem("ili", "true")
      window.localStorage.setItem("cat", customerAccountResponse.data.accessToken)
      window.localStorage.setItem("ttl", customerAccountResponse.data.expiresAt)
      const customerData = await getShopifyCustomerData(customerAccountResponse.data.accessToken)
      const customer = customerData.data.customer.customer
      dlLoginTrack(customer)
        .then(window.location.href = multipassResponse.data.url)
    } else {
      setError("Unidentified customer")
    }
  }

  const now = new Date()

  let loggedInExpiryDate = null

  if (typeof window !== "undefined" && typeof window.localStorage.getItem("ttl") !== null) {
    loggedInExpiryDate = new Date(window.localStorage.getItem("ttl"))
  }

  if (typeof window !== "undefined") {
    if (now > loggedInExpiryDate) {
      window.localStorage.removeItem("ili")
      window.localStorage.removeItem("cat")
      window.localStorage.removeItem("ttl")
    }
  }

  return (
    <FormContainer>
      <Title>Login</Title>
      <form onSubmit={handleSubmit} name="loginForm">
        <InputContainer>
          <ServerError>{error}</ServerError>
        </InputContainer>
        <InputContainer>
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            name="customer[email]"
            autoCapitalize="off"
            autoComplete="email"
            autoCorrect="off"
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            name="customer[password]"
            autoCapitalize="off"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputContainer>
        <SubmitButton type={"submit"}>
          Login
        </SubmitButton>
      </form>
      <Navigations>
        <RegisterButtonContainer>
          <TextButton to="/account/register/">
            Register
          </TextButton>
          <Claimer>(For existing customers)</Claimer>
        </RegisterButtonContainer>
        <TextButton to="/account/recover/">Forgot Password?</TextButton>
      </Navigations>
    </FormContainer>
  )
}

export default LoginForm
